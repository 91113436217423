<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card>
      <b-tabs>
        <b-tab title="Información de Tavo Mensajeria">
          <b-card-text v-if="company">
            <validation-observer ref="formValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <b-tabs color="secondary" justified>
                  <b-tab title="Datos generales">
                    <div class="row">
                      <div class="col">
                        <b-form-group
                          label="Nombre de la compañia*"
                          label-for="name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Nombre"
                            rules="required"
                          >
                            <b-form-input
                              id="name"
                              v-model="company.name"
                              :state="errors.length > 0 ? false : null"
                              name="Nombre"
                              placeholder="Nombre del servicio"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group
                          label="Email de la compañia*"
                          label-for="email"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Email de la compañia"
                            rules="required"
                          >
                            <b-form-input
                              id="email"
                              v-model="company.email"
                              :state="errors.length > 0 ? false : null"
                              name="Nombre"
                              placeholder="Email de la compañia"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group
                          label="Actividad economica"
                          label-for="economic"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Actividad economica"
                            rules="required"
                          >
                            <v-select
                              v-model="company.economic_activity"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="$store.state.app.economic_activities"
                              :clearable="false"
                              class="per-page-selector mr-1"
                              :state="errors.length > 0 ? false : null"
                              label="name"
                              name="Actividad economica"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group
                          label="Tipo de identificación"
                          label-for="identification_type"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de identificación"
                            rules="required"
                          >
                            <v-select
                              v-model="company.identification_type"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="identificationTypes"
                              :clearable="false"
                              class="per-page-selector mr-1"
                              label="text"
                              name="Tipo de identificación"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group
                          label="Número de identificación *"
                          label-for="name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Número de identificación"
                            rules="required"
                          >
                            <b-form-input
                              id="name"
                              v-model="company.identification_number"
                              :state="errors.length > 0 ? false : null"
                              name="Numero de identificación"
                              placeholder="Número de identificación"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group label="Teléfono *" label-for="phone">
                          <validation-provider
                            #default="{ errors }"
                            name="Teléfono"
                            rules="required"
                          >
                            <b-form-input
                              id="name"
                              v-model="company.phone"
                              :state="errors.length > 0 ? false : null"
                              name="Teléfono"
                              placeholder="Teléfono"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <label for="">Logo de la compañia</label>
                        <b-form-file v-model="select_logo" accept="p12" />
                      </div>
                    </div>
                  </b-tab>

                  <b-tab title="Ubicación y dirección">
                    <div class="row">
                      <div class="col">
                        <b-form-group label="Provincia" label-for="province">
                          <validation-provider
                            #default="{ errors }"
                            name="Provincia"
                            rules="required"
                          >
                            <v-select
                              v-model="company.province"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="$store.state.app.provinces"
                              :clearable="false"
                              class="per-page-selector mr-1"
                              :state="errors.length > 0 ? false : null"
                              label="name"
                              name="Provincia"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group label="Cantón" label-for="canton">
                          <validation-provider
                            #default="{ errors }"
                            name="Cantón"
                            rules="required"
                          >
                            <v-select
                              v-model="company.canton"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="$store.state.app.cantons"
                              :clearable="false"
                              class="per-page-selector mr-1"
                              :state="errors.length > 0 ? false : null"
                              label="name"
                              name="Cantón"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group label="Distrito" label-for="district">
                          <validation-provider
                            #default="{ errors }"
                            name="Distrito"
                            rules="required"
                          >
                            <v-select
                              v-model="company.district"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="$store.state.app.districts"
                              :clearable="false"
                              class="per-page-selector mr-1"
                              :state="errors.length > 0 ? false : null"
                              label="name"
                              name="Distrito"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group label="Barrio" label-for="barrio">
                          <validation-provider
                            #default="{ errors }"
                            name="Barrio"
                            rules="required"
                          >
                            <v-select
                              v-model="company.barrio"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="$store.state.app.barrios"
                              :clearable="false"
                              class="per-page-selector mr-1"
                              :state="errors.length > 0 ? false : null"
                              label="name"
                              name="Barrio"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3">
                        <b-form-group label="Dirección *" label-for="address">
                          <validation-provider
                            #default="{ errors }"
                            name="Dirección"
                            rules="required"
                          >
                            <b-form-textarea
                              v-model="company.address"
                              id="address"
                              placeholder="Dirección"
                              rows="1"
                              :state="errors.length > 0 ? false : null"
                              max-rows="4"
                              name="Dirección"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col-3">
                        <b-form-group
                          label="Otras señas (hacienda)*"
                          label-for="othersigns"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Otras señas"
                            rules="required"
                          >
                            <b-form-textarea
                              v-model="company.other_signs"
                              id="othersigns"
                              placeholder="Otras señas"
                              rows="1"
                              :state="errors.length > 0 ? false : null"
                              max-rows="4"
                              name="Otras señas"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab title="Datos de hacienda">
                    <div class="row">
                      <div class="col">
                        <b-form-group label="Usuario *" label-for="username">
                          <validation-provider
                            #default="{ errors }"
                            name="Usuario"
                            rules="required"
                          >
                            <b-form-input
                              id="name"
                              v-model="company.username"
                              :state="errors.length > 0 ? false : null"
                              name="Usuario"
                              placeholder="Usuario"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group label="Contraseña *" label-for="pass">
                          <validation-provider
                            #default="{ errors }"
                            name="Contraseña"
                            rules="required"
                          >
                            <b-form-input
                              id="pass"
                              type="password"
                              v-model="company.password"
                              :state="errors.length > 0 ? false : null"
                              name="Contraseña"
                              placeholder="Contraseña"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group label="PIN *" label-for="pin">
                          <validation-provider
                            #default="{ errors }"
                            name="PIN"
                            rules="required"
                          >
                            <b-form-input
                              id="pin"
                              v-model="company.pin"
                              :state="errors.length > 0 ? false : null"
                              name="PIN"
                              placeholder="PIN"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group
                          label="Llave criptografica *"
                          label-for="filekey"
                        >
                          <b-form-file
                            v-model="select_file"
                            id="filekey"
                            accept="p12"
                            name="Llave criptografica"
                          />
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <b-form-checkbox v-model="company.test" switch inline>
                          Modo de pruebas
                        </b-form-checkbox>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>

                <hr />

                <div class="text-right">
                  <b-overlay
                    :show="overlay"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button
                      type="submit"
                      variant="gradient-primary"
                      block
                      @click="storeInfo"
                    >
                      Guardar
                    </b-button>
                  </b-overlay>
                </div>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Swal from "sweetalert2";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UtilityService from "@/services/utilities.service";
import vSelect from "vue-select";
import { mapActions } from "vuex";

import { identificationInLetter } from "@/helpers";

export default {
  components: {
    BButton,
    BFormCheckbox,
    BFormFile,
    BFormTextarea,
    vSelect,
    BCard,
    BTabs,
    BCardText,
    BTab,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay
  },
  data() {
    return {
      required,
      email,
      overlay: false,
      loading: false,

      select_file: [],
      select_logo: [],

      company: null,
      identificationTypes: [
        { value: "01", text: "Cédula fisica" },
        { value: "02", text: "Cédula juridica" },
        { value: "03", text: "DIMEX" },
        { value: "04", text: "NITE" }
      ]
    };
  },
  watch: {
    select_file(newval) {
      this.company.file_key = newval;
    },
    select_logo(newval) {
      this.company.logo = newval;
    }
  },
  methods: {
    storeInfo() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          this.overlay = true;
          try {
            const formData = new FormData();
            formData.append("name", this.company.name);
            formData.append("email", this.company.email);
            formData.append(
              "economic_activity",
              this.company.economic_activity.id
            );
            formData.append(
              "identification_type",
              this.company.identification_type.value
            );
            formData.append(
              "identification_number",
              this.company.identification_number
            );
            formData.append("phone", this.company.phone);
            formData.append("province", this.company.province.id);
            formData.append("canton", this.company.canton.id);
            formData.append("district", this.company.district.id);
            formData.append("barrio", this.company.barrio.id);
            formData.append("address", this.company.address);
            formData.append("other_signs", this.company.other_signs);
            formData.append("username", this.company.username);
            formData.append("password", this.company.password);
            formData.append("pin", this.company.pin);
            formData.append("test", this.company.test);
            formData.append("logo", this.company.logo);
            formData.append("file_key", this.company.file_key);

            const { data: res } = await UtilityService.updateCompanyInfo(
              formData
            );
            if (res.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Datos modificados correctamente",
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            } else {
              Swal.fire({
                title: res.message,
                text: "",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: false
              });
            }
            this.overlay = false;
          } catch (err) {
            Swal.fire({
              title: "Ha ocurrido un error interno",
              text: "",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: false
            });
            this.overlay = false;
          }
        } else {
          Swal.fire({
            title: "Falta llenar campos obligatorios",
            text: "",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        }
      });
    },
    ...mapActions({
      getProvinces: "app/getProvinces",
      getCantons: "app/getCantons",
      getDistricts: "app/getDistricts",
      getBarrios: "app/getBarrios"
    }),
    identificationInLetter(number) {
      return identificationInLetter(number);
    },
    getCantonsC() {
      this.getCantons({
        province: this.company.province.number
      });
    },
    getDistrictsC() {
      this.getDistricts({
        province: this.company.province.number,
        canton: this.company.canton.number
      });
    },
    getBarriosC() {
      this.getBarrios({
        province: this.company.province.number,
        canton: this.company.canton.number,
        district: this.company.district.number
      });
    },
    async getCompanyInfo() {
      this.loading = true;
      const { data: res } = await UtilityService.getCompanyInfo();
      this.company = res.data;
      this.company.identification_type = {
        value: this.company.identification_type,
        text: this.identificationInLetter(this.company.identification_type)
      };
      setTimeout(() => {
        this.getCantonsC();
        this.getDistrictsC();
        this.getBarriosC();
      }, 1000);
      this.loading = false;
    }
  },
  created() {
    this.getCompanyInfo();
    this.getProvinces();
  }
};
</script>
