var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-card',[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Información de Tavo Mensajeria"}},[(_vm.company)?_c('b-card-text',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-tabs',{attrs:{"color":"secondary","justified":""}},[_c('b-tab',{attrs:{"title":"Datos generales"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Nombre de la compañia*","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"Nombre","placeholder":"Nombre del servicio"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3963170819)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Email de la compañia*","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email de la compañia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"Nombre","placeholder":"Email de la compañia"},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2640857664)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Actividad economica","label-for":"economic"}},[_c('validation-provider',{attrs:{"name":"Actividad economica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.economic_activities,"clearable":false,"state":errors.length > 0 ? false : null,"label":"name","name":"Actividad economica"},model:{value:(_vm.company.economic_activity),callback:function ($$v) {_vm.$set(_vm.company, "economic_activity", $$v)},expression:"company.economic_activity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2265231451)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Tipo de identificación","label-for":"identification_type"}},[_c('validation-provider',{attrs:{"name":"Tipo de identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.identificationTypes,"clearable":false,"label":"text","name":"Tipo de identificación","state":errors.length > 0 ? false : null},model:{value:(_vm.company.identification_type),callback:function ($$v) {_vm.$set(_vm.company, "identification_type", $$v)},expression:"company.identification_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2878809989)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Número de identificación *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Número de identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"Numero de identificación","placeholder":"Número de identificación"},model:{value:(_vm.company.identification_number),callback:function ($$v) {_vm.$set(_vm.company, "identification_number", $$v)},expression:"company.identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1196211278)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Teléfono *","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"Teléfono","placeholder":"Teléfono"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,996110571)})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Logo de la compañia")]),_c('b-form-file',{attrs:{"accept":"p12"},model:{value:(_vm.select_logo),callback:function ($$v) {_vm.select_logo=$$v},expression:"select_logo"}})],1)])]),_c('b-tab',{attrs:{"title":"Ubicación y dirección"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Provincia","label-for":"province"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.provinces,"clearable":false,"state":errors.length > 0 ? false : null,"label":"name","name":"Provincia"},model:{value:(_vm.company.province),callback:function ($$v) {_vm.$set(_vm.company, "province", $$v)},expression:"company.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3615017014)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Cantón","label-for":"canton"}},[_c('validation-provider',{attrs:{"name":"Cantón","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.cantons,"clearable":false,"state":errors.length > 0 ? false : null,"label":"name","name":"Cantón"},model:{value:(_vm.company.canton),callback:function ($$v) {_vm.$set(_vm.company, "canton", $$v)},expression:"company.canton"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3208524516)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Distrito","label-for":"district"}},[_c('validation-provider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.districts,"clearable":false,"state":errors.length > 0 ? false : null,"label":"name","name":"Distrito"},model:{value:(_vm.company.district),callback:function ($$v) {_vm.$set(_vm.company, "district", $$v)},expression:"company.district"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2602167787)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Barrio","label-for":"barrio"}},[_c('validation-provider',{attrs:{"name":"Barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.barrios,"clearable":false,"state":errors.length > 0 ? false : null,"label":"name","name":"Barrio"},model:{value:(_vm.company.barrio),callback:function ($$v) {_vm.$set(_vm.company, "barrio", $$v)},expression:"company.barrio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4294579012)})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Dirección *","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","placeholder":"Dirección","rows":"1","state":errors.length > 0 ? false : null,"max-rows":"4","name":"Dirección"},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,636140816)})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Otras señas (hacienda)*","label-for":"othersigns"}},[_c('validation-provider',{attrs:{"name":"Otras señas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"othersigns","placeholder":"Otras señas","rows":"1","state":errors.length > 0 ? false : null,"max-rows":"4","name":"Otras señas"},model:{value:(_vm.company.other_signs),callback:function ($$v) {_vm.$set(_vm.company, "other_signs", $$v)},expression:"company.other_signs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,679079375)})],1)],1)])]),_c('b-tab',{attrs:{"title":"Datos de hacienda"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Usuario *","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"Usuario","placeholder":"Usuario"},model:{value:(_vm.company.username),callback:function ($$v) {_vm.$set(_vm.company, "username", $$v)},expression:"company.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4173470625)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Contraseña *","label-for":"pass"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"pass","type":"password","state":errors.length > 0 ? false : null,"name":"Contraseña","placeholder":"Contraseña"},model:{value:(_vm.company.password),callback:function ($$v) {_vm.$set(_vm.company, "password", $$v)},expression:"company.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1713657167)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"PIN *","label-for":"pin"}},[_c('validation-provider',{attrs:{"name":"PIN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"pin","state":errors.length > 0 ? false : null,"name":"PIN","placeholder":"PIN"},model:{value:(_vm.company.pin),callback:function ($$v) {_vm.$set(_vm.company, "pin", $$v)},expression:"company.pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3946634512)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Llave criptografica *","label-for":"filekey"}},[_c('b-form-file',{attrs:{"id":"filekey","accept":"p12","name":"Llave criptografica"},model:{value:(_vm.select_file),callback:function ($$v) {_vm.select_file=$$v},expression:"select_file"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-checkbox',{attrs:{"switch":"","inline":""},model:{value:(_vm.company.test),callback:function ($$v) {_vm.$set(_vm.company, "test", $$v)},expression:"company.test"}},[_vm._v(" Modo de pruebas ")])],1)])])],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"gradient-primary","block":""},on:{"click":_vm.storeInfo}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }